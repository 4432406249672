<template>
  <div style="height: inherit">
    {{ goToTasks() }}
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'

export default {
  methods: {
    goToTasks() {
      const token = getToken()
      window.open(`https://kanban.salomhayot.uz/#/auth?token=${token}`)
      this.$router.push('/')
    },
  },
}
</script>
